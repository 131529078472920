body {
  margin: 0;
  padding: 1em;
  font-family: sans-serif;
  background: #f0f0f0;
}

iframe {
  display: none;
}

.app {
  justify-content: center;
  display: grid;
}

.start-button {
  background: #003540;
  border-radius: 2em;
  color: #dadada;
  display: inline-block;
  font-size: 1.2em;
  font-weight: bold;
  padding: 0.5em 2em;
  text-align: center;
  text-decoration: none;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 45%;
  -ms-transform: translateY(-50%, -45%);
  transform: translateY(-50%, -45%);
}

.start-button:hover {
  opacity: 0.7;
  color: black;
}

.game {
  display: flex;
}

.status {
  width: 20%;
  align-items: center;
  display: flex;
}

.production {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: solid;
  height: 67px;
}

.statusElementContainer {
  width: 90%;
}

.targetTurn {
  font-size: 3em;
  margin-left: 10px;
}

.status .turn {
  border-radius: 50%;
  background: #003540;
  height: 6em;
  width: 6em;
  text-align: center;
  color: #dadada;
  line-height: 3em;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.status .turn .number {
  font-size: 3em;
  font-weight: bold;
  line-height: 0.4em;
}

.gameBoard {
  width: 80%;
  margin-left: 10px;
}

[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -moz-user-drag: element;
  -webkit-user-drag: element;
  -khtml-user-drag: element;
  user-drag: element;
}


svg g {
  fill: #005F73;
  fill-opacity: 0.6;
}

svg g polygon {
  stroke: #005F73;
  stroke-width: 0;
  transition: fill-opacity .2s;
}

svg g text {
  font-size: 0.15em;
  fill: #FFFFFF;
  fill-opacity: 1;
  transition: fill-opacity .2s;
}

svg path {
  fill: none;
  stroke: hsl(60, 20%, 70%);
  stroke-width: 0.4em;
  stroke-opacity: 0.3;
  fill-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
}

svg g.movable polygon {
  stroke: crimson;
  stroke-width: 0.3;
}

svg path.empire1 {
  fill: #FF7D00;
  stroke: #FF7D00;
}

svg path.empire2 {
  fill: #78290F;
  stroke: #78290F;
}

svg path.empire3 {
  fill: #FFD131;
  stroke: #FFD131;
}

svg path.empire4 {
  fill: #2A2B2A;
  stroke: #2A2B2A;
}

.healthbar {
  stroke-width: 0.5;
  stroke: green;
}

.lowhealth {
  stroke: #AE2012;
}
